import React from "react"

import styled from "styled-components"

const IframeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
interface ResponsiveIFrameProps {
  src: string
}

const ResponsiveIFrame: React.FC<ResponsiveIFrameProps> = ({ src }) => (
  <IframeWrapper>
    <iframe
      src={src}
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
    />
  </IframeWrapper>
)

export default ResponsiveIFrame
