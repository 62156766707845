import React from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "./Theme"
import { Link } from "gatsby"
import { GatsbyProps } from "Src/global-types"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import CssReset from "Components/core/CssReset"
import GlobalStyles from "Components/core/GlobalStyles"

const Wrapper = styled.div`
  display: grid;
  background: ${({ theme }): string => theme.colours.light.background.primary};
  grid-template-columns: 1fr minmax(320px, 1000px) 1fr;
  grid-template-rows: minmax(10vh, auto) minmax(80vh, auto);
  & > * {
    grid-column: 2/3;
  }
`

const Nav = styled.nav`
  ul {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`

const Footer = styled.footer`
  background: ${({ theme }): string =>
    theme.colours.light.background.secondary};
  height: 200px;
  display: grid;
  align-content: center;
  border-radius: 0 30px 0 0;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  position: relative;
  &::after,
  &::before {
    top: -20px;
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
  }
  &::after {
    background: ${({ theme }): string =>
      theme.colours.light.background.primary};
    border-radius: 0 0 0 30px;
  }
  &::before {
    background: ${({ theme }): string =>
      theme.colours.light.background.secondary};
  }
`

const Header = styled.header`
  display: flex;
  justify-content: center;
  background: ${({ theme }): string =>
    theme.colours.light.background.secondary};
`
const HeaderContents = styled.div`
  max-width: 1000px;
  margin: 20px;
  width: 100%;
`
const ColouredSide = styled.div`
  grid-row: 2 / 100;
  grid-column: 1/2;
  background: ${({ theme }): string =>
    theme.colours.light.background.secondary};
  position: relative;
  &::after,
  &::before {
    top: 0;
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    right: -20px;
  }
  &::after {
    background: ${({ theme }): string =>
      theme.colours.light.background.primary};
    border-radius: 30px 0px 0 0;
  }
  &::before {
    background: ${({ theme }): string =>
      theme.colours.light.background.secondary};
  }
`
const ColouredLeftCorner = styled.div`
  grid-row: 1 / 2;
  grid-column: 1/2;
  background: ${({ theme }): string =>
    theme.colours.light.background.secondary};
`
const ColouredRightCorner = styled.div`
  grid-row: 1 / 2;
  grid-column: 3/3;
  background: ${({ theme }): string => theme.colours.light.background.primary};
  position: relative;
  &::after,
  &::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: -20px;
    height: 20px;
    width: 20px;
  }
  &::after {
    content: "";
    background: ${({ theme }): string =>
      theme.colours.light.background.secondary};
    border-radius: 0 0 30px 0;
  }
  &::before {
    background: ${({ theme }): string =>
      theme.colours.light.background.primary};
  }
`

const Main = styled.main`
  margin: 20px;
`
interface StandardLayoutProps {
  headerContent?: JSX.Element
  mainContent?: JSX.Element
}

const StandardLayout: React.FC<StandardLayoutProps & GatsbyProps> = ({
  headerContent,
  mainContent,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssReset />
      <GlobalStyles />
      <Wrapper>
        <ColouredLeftCorner />
        <ColouredRightCorner />
        <ColouredSide />
        <Header>
          <HeaderContents>
            <Nav>
              <ul>
                <li>
                  <Link activeClassName="is-active" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link activeClassName="is-active" to="/projects">
                    Projects
                  </Link>
                </li>
                <li>
                  <Link activeClassName="is-active" to="/professional">
                    Professional
                  </Link>
                </li>
                <li>
                  <Link activeClassName="is-active" to="/personal">
                    Personal
                  </Link>
                </li>
                <li>
                  <Link activeClassName="is-active" to="/blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link activeClassName="is-active" to="/presentations">
                    Presentations
                  </Link>
                </li>
              </ul>
            </Nav>
            {headerContent}
          </HeaderContents>
        </Header>

        <Main>
          {mainContent}
          {children}
        </Main>
        <Footer>
          <ul>
            <li>
              <OutboundLink href="mailto:liammylesdesing@gmail.com">
                <span>Email</span>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://github.com/LiamMyles"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>Github</span>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://twitter.com/LiamMyles92"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>Twitter</span>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://www.linkedin.com/in/liammyles/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>LinkedIn</span>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://dev.to/liammyles"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>DevTo</span>
              </OutboundLink>
            </li>
          </ul>
        </Footer>
      </Wrapper>
    </ThemeProvider>
  )
}

export default StandardLayout
