import { DefaultTheme } from "styled-components"

// const siteColours = {
//   pink: "#b71c9d",
//   lightPink: "#f1dfff",
//   blue: "#045ce0",
//   white: "#ffffff",
// }

const colours = {
  background: {
    secondary: "#ddd9e8",
    primary: "#fcfcff",
    accentPrimary: "#9C38FF",
    accentSecondary: "#faf6ff",
  },
  text: {
    link: {
      display: "#6a00d5", //#005964 "#B200FF" #930077
      focus: "#930077", //#6a00d5 "#00FFB2"
      active: "black",
    },
    body: "black",
    heading: "black",
  },
}

//  "#ffb200"
const theme: DefaultTheme = {
  colours: {
    light: {
      ...colours,
    },
    dark: {
      ...colours,
      background: {
        secondary: "#feb67f",
        primary: "#f7fdff",
        accentPrimary: "#6a00d5",
        accentSecondary: "#faf6ff",
      },
    },
  },
  spacing: {
    large: "1.5rem",
    medium: "1rem",
    small: "0.5rem",
  },
}

export default theme
