import { createGlobalStyle } from "styled-components"

// https://github.com/nicolas-cusan/destyle.css/blob/master/destyle.css

const CssReset = createGlobalStyle`
* {
  box-sizing: border-box;
}

::before,
::after {
  box-sizing: inherit;
}



html {
}



body {
  margin: 0;
}


main {
  display: block;
}


p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}


ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}


dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}



hr {
  border: 0;
  border-top: 1px solid;
  margin: 0;
  clear: both;
  color: inherit;
}


pre {
}

address {
  font-style: inherit;
}



a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}


abbr[title] {
}


b,
strong {
  font-weight: bolder;
}


code,
kbd,
samp {
}


small {
  font-size: 80%;
}


sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}



img {
  border-style: none;
  vertical-align: bottom;
}

embed,
object,
iframe {
  border: 0;
  vertical-align: bottom;
}



button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit;
}

[type='checkbox'] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

[type='radio'] {
  -webkit-appearance: radio;
  appearance: radio;
}


button,
input {
  overflow: visible;
}


button,
select {
  text-transform: none;
}


button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

button[disabled],
[type='button'][disabled],
[type='reset'][disabled],
[type='submit'][disabled] {
  cursor: default;
}


button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}


button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}


fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
}


legend {
}


progress {
  vertical-align: baseline;
}


textarea {
  overflow: auto;
}


[type='checkbox'],
[type='radio'] {
}


[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}


[type='search'] {
}


[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}


::-webkit-file-upload-button {
}


label[for] {
  cursor: pointer;
}



details {
  display: block;
}


summary {
  display: list-item;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  text-align: left;
}

td,
th {
  vertical-align: top;
}

th {
  text-align: left;
  font-weight: bold;
}



template {
  display: none;
}


[hidden] {
  display: none;
}`

export default CssReset
