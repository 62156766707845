import React from "react"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const FirstWebsite: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="First Website" />
    <h1>First Website</h1>
    <p>
      The first websites I created was for a university module in DCU, as part
      of a 2 man team we were tasked with the creation of a website based off a
      fictional character that was to be treated as a real person. For my
      website we used David Mills from the movie Se7en.
    </p>
    <p>
      The website itself was simple enough as it was to be built using only CSS
      and HTML, however I do remember being proud of myself for figuring out a
      way to make a gallery using iframes.
    </p>
    <p>
      Check it out in the iFrame or you can visit it on
      <a href="https://liammyles.github.io/past-static-projects/david-mills/">
        Github Pages
      </a>
    </p>
    <ResponsiveIFrame src="https://liammyles.github.io/past-static-projects/david-mills/" />
  </StandardLayout>
)

export default FirstWebsite
